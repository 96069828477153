import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { useClickOutside } from '@mesa-labs/mesa-ui';

import { useSelector } from '../../../redux/hooks';
import { copyToClipboard } from '../../../utils';
import BackButton from './BackButton';
import ProgressBar from './ProgressBar';
import { OnboardingEvents, trackEvent } from '../../../analytics';

const MainContainer = styled.div`
  background-image: url("/assets/fullscreen-gradient-background.png");	
  background-position: bottom right;	
  background-repeat: no-repeat;	
  background-size: contain;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    flex-direction: row;
  }

  @media (min-width: 1600px) and (max-height: 800px) {
    background-size: cover;
  }
`;

const Header = styled.div<{ useHeaderBorder: boolean }>`
  align-items: center;
  background: ${(props) => props.theme.colors.White};
  border-bottom: 1px solid ${(props) => (props.useHeaderBorder && '#E1E4EA') || 'transparent'};
  display: flex;
  height: 72px;
  justify-content: center;
  left: 50%;
  position: fixed;
  top: 0px;
  transform: translateX(-50%);
  width: 100%;
  z-index: 101;
`;

const HeaderInner = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.White};
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0px 24px;
  max-width: 1600px;
  padding: 24px 0px;
  width: 100%;
`;

const Logo = styled.img<{ height?: number }>`
  align-self: center;
  display: flex;
  height: ${(props) => props.height || 19}px;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    align-self: flex-start;
    display: block;
    margin-top: 4px;
  }
`;

const SupportContainer = styled.div`
  align-items: center;
  color: #525866;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  gap: 16px;
  letter-spacing: -0.084px;
  line-height: 20px;
  position: relative;
`;

const SupportDropdown = styled(motion.div)`
  background: #FFF;
  border-radius: 16px;
  border: 1px solid #E1E4EA;
  box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.10);
  color: #0E121B;
  display: flex;
  flex-direction: column;;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.084px;
  line-height: 20px; /* 142.857% */
  padding: 8px;
  position: absolute;
  right: 0px;
  top: 52px;
  width: 220px;
`;

const SupportDropdownItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 8px;
  transition: background 300ms ease-in-out;

  &:hover {
    background: #F5F7FA;
    border-radius: 8px;
  }

  &:first-of-type {
    margin-bottom: 8px;
  }

  &:last-of-type {
    margin-top: 8px;
  }
`;

const SupportDropdownLine = styled.div`
  background: #E1E4EA;
  height: 1px;
  width: 100%;  
`;

const SupportIcon = styled.img``;

const ActionButton = styled.a`
  background: #FFF;
  border-radius: 10px;
  border: 1px solid #E1E4EA;
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  color: #525866;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.084px;
  line-height: 20px;
  padding: 10px 14px;
  transition: background 300ms ease-in-out;

  &:hover {
    background: #F9FAFB;
  }
`;

const ActionButtonEmailDesktop = styled(ActionButton)`
  display: none;  

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: block;
  }
`;

const ActionButtonEmailMobile = styled(ActionButton)`
  display: flex;  

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: none;
  }
`;

const LinkButton = styled.a`
  color: #0E121B;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  text-decoration-line: underline;  

  &:hover {
    text-decoration-line: none;
  }
`;

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0px auto;
  max-width: 1200px;
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 0px;
  padding: calc(72px + 40px) 20px;
  position: relative;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: calc(72px + 48px) 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Laptop}) {
    padding: calc(72px + 48px) 40px 32px 40px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Desktop}) {
    padding: calc(72px + 48px) 50px;
  }
`;

const DesktopBackButtonContainer = styled.div`
  display: none;
  left: 0px;
  margin: 0px 24px;
  position: absolute;
  top: calc(72px + 20px);

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    top: calc(72px + 48px);
  }

  @media (min-width: 968px) {
    display: block;
  }
`;

const MobileBackButtonContainer = styled.div`
  align-self: flex-start;
  display: block;
  margin-bottom: 24px;

  @media (min-width: 968px) {
    display: none;
  }
`;

const SignInContainer = styled.div`
  align-items: center;
  color: #525866;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  gap: 16px;
  justify-content: space-between;
  letter-spacing: -0.084px;
  line-height: 20px;

  & span {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    & span {
      display: block;
    }
  }
`;

type OnboardingLayoutProps = {
  children: ReactElement | ReactElement[];
  showProgressBar?: boolean;
  rightHeaderVariant?: 'sign_in' | 'contact_support' | 'start_over';
  useHeaderBorder?: boolean;
};

function OnboardingLayout({
  children,
  showProgressBar = true,
  rightHeaderVariant = 'contact_support',
  useHeaderBorder = true,
}: OnboardingLayoutProps): ReactElement {
  const supportDropdownRef = useRef(null);
  const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);
  const [showCopyCheck, setShowCopyCheck] = useState(false);

  // reset copy check after 2s:
  useEffect(() => {
    if (showCopyCheck) {
      setTimeout(() => {
        setShowCopyCheck(false);
      }, 2000);
    }
  }, [showCopyCheck]);

  useClickOutside(supportDropdownRef, () => {
    setSupportDropdownOpen(false);
  });

  const brand = useSelector((state) => state.ui.brand);

  const logoSrc = useMemo(() => {
    if (process.env.NODE_ENV == 'production') {
      return 'mesa-purple.svg';
    }

    switch (brand) {
      /** @TODO (th-mesa-2565): update brand logos to use new bg color */
      case 'cardinal':
        return 'mesa-cardinal-white.svg';
      default:
        return 'mesa-purple.svg';
    }
  }, [brand]);

  const logoHeight = useMemo(() => {
    if (process.env.NODE_ENV == 'production') {
      return 19;
    }

    switch (brand) {
      case 'cardinal':
        return 25;
      default:
        return 18;
    }
  }, [brand]);

  const rightHeaderVariantNode = useMemo(() => {
    switch (rightHeaderVariant) {
      case 'sign_in':
        return (
          <SignInContainer>
            <span>Already have an account?</span>
            <ActionButton
              href="/sign-in"
              type="button"
            >
              Sign In
            </ActionButton>
          </SignInContainer>
        );
      case 'start_over':
        return (
          <SignInContainer>
            <span>Wrong email?</span>
            <LinkButton href="/sign-up">Start over</LinkButton>
          </SignInContainer>
        );
      default:
      case 'contact_support':
        return (
          <SupportContainer ref={supportDropdownRef}>
            <ActionButtonEmailDesktop
              onClick={() => {
                trackEvent(OnboardingEvents.ContactSupportClick);
                setSupportDropdownOpen(!supportDropdownOpen);
              }}
              type="button"
            >
              Contact Support
            </ActionButtonEmailDesktop>

            <AnimatePresence>
              {supportDropdownOpen && (
                <SupportDropdown
                  animate={{
                    opacity: [0, 1],
                    scale: [0.9, 1],
                  }}
                  exit={{
                    opacity: [1, 0],
                    scale: [1, 0.9],
                  }}
                >
                  <SupportDropdownItem>
                    <SupportIcon src="/assets/phone-support-icon.svg" />
                    <span>
                      312-883-0565
                    </span>
                  </SupportDropdownItem>

                  <SupportDropdownLine />

                  <SupportDropdownItem>
                    <span>
                      support@joinmesa.com
                    </span>

                    {!showCopyCheck && (
                      <SupportIcon
                        onClick={() => copyToClipboard('support@joinmesa.com', () => {
                          trackEvent(OnboardingEvents.ContactSupportCopyEmailClick);
                          setShowCopyCheck(true);
                        })}
                        src="/assets/file-line-copy-icon.svg"
                      />
                    )}

                    {showCopyCheck && (
                      <SupportIcon
                        src="/assets/green-check-circle-icon.svg"
                      />
                    )}
                  </SupportDropdownItem>
                </SupportDropdown>
              )}
            </AnimatePresence>

            {/* @todo dropdown */}
            <ActionButtonEmailMobile
              href="mailto:support@joinmesa.com"
              type="button"
            >
              <img src="/assets/email-icon.svg" alt="Email icon to contact support" />
            </ActionButtonEmailMobile>
          </SupportContainer>
        );
    }
  }, [rightHeaderVariant, supportDropdownOpen, showCopyCheck]);

  return (
    <MainContainer>
      <Header useHeaderBorder={useHeaderBorder}>
        <HeaderInner>
          <Logo height={logoHeight} src={`/assets/${logoSrc}`} alt="Mesa logo" />

          {showProgressBar && <ProgressBar />}

          {rightHeaderVariantNode}
        </HeaderInner>
      </Header>

      <ContentContainer>
        <Content>
          <DesktopBackButtonContainer>
            <BackButton />
          </DesktopBackButtonContainer>

          <MobileBackButtonContainer>
            <BackButton />
          </MobileBackButtonContainer>

          {children}
        </Content>
      </ContentContainer>
    </MainContainer>
  );
}

export default OnboardingLayout;
